<template>
  <h2>404</h2>
  <h3>Page not found</h3>
  <p>
    If there is a problem with the website, please contact Hayden Collins at
    hayden.collins@uvm.edu
  </p>
</template>

<script>
export default {};
</script>

<style></style>
